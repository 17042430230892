@import url('https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700');
/* The following line is used to measure usage of this code in production. For more info see our usage billing page */
@import url('https://px.animaapp.com/63903aab9f48cf6dd8bf8e60.63903aaba457c59431dc4ff6.63903c7a1fe158c49a83dd1b.hcp.png');

/* screen - frame-2 */

.frame-2 {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--white);
  display: flex;
  gap: 16px;
  height: 90px;
  width: 728px;
  padding: 12px;
}

.frame-2 .frame-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  width: 222px;
}

.frame-2 .stimpacketh {
  align-self: stretch;
  color: var(--black);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-l);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -0.5px;
  white-space: nowrap;
}

.frame-2 .get-real-time-nft-in {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 20px;
}

.frame-2 .span1 {
  color: var(--black);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-m);
  font-weight: 700;
}

.frame-2 .full-table {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  overflow: hidden;
  width: 222px;
}

.frame-2 .table {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--white);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-top: -1.5px;
  overflow: hidden;
}

.frame-2 .row-main-recomponentize-duplicate {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  padding: 0px 0px 3px;
}

.frame-2 .cell {
  align-items: flex-start;
  display: flex;
  height: 21px;
  width: 130px;
}

.frame-2 .row-cell-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: center;
  margin-right: -1px;
  padding: 0px 5px;
  width: fit-content;
}

.frame-2 .text {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.frame-2 .primary-text {
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  white-space: nowrap;
  width: fit-content;
}

.frame-2 .cell-1 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  height: 21px;
}

.frame-2 .row-cell {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  gap: 8px;
  justify-content: center;
  padding: 0px 12px;
}

.frame-2 .text-1 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.frame-2 .primary-text-1 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  white-space: nowrap;
}

.frame-2 .row-cell-1 {
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: center;
  padding: 0px 5px;
  width: fit-content;
}

.frame-2 .row-main-recomponentize-duplicate-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
}

.frame-2 .frame-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  width: 222px;
}

.frame-2 .button {
  align-items: center;
  align-self: stretch;
  background-color: var(--purple600);
  border-radius: 6px;
  display: flex;
  justify-content: center;
}

.frame-2 .sign-up-for-nansen {
  color: var(--white);
  flex: 1;
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-m);
  font-weight: 700;
  height: 46px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  text-align: center;
}

.screen a {
  display: contents;
  text-decoration: none;
}

.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

* {
  box-sizing: border-box;
}

:root {
  --black: #000000;
  --purple600: #6b46c1;
  --white: #ffffff;

  --font-size-l: 18px;
  --font-size-m: 16px;
  --font-size-m2: 14px;

  --font-family-source_sans_pro: 'Source Sans Pro', Helvetica;
}
.sourcesanspro-normal-black-14px {
  color: var(--black);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-m2);
  font-style: normal;
  font-weight: 400;
}

.sourcesanspro-normal-black-16px {
  color: var(--black);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.sourcesanspro-bold-black-14px {
  color: var(--black);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-m2);
  font-style: normal;
  font-weight: 700;
}
